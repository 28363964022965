import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Router } from '@reach/router'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'

import Map from '../components/views/Map/Map'
import { getParsedMetrics } from '../components/misc/Queries'

// local components
import { LoadingSpinner } from '../components/common'

const IndexPage = () => {
  // STATE  // --------------------------------------------------------------//
  const [loading, setLoading] = useState(true)
  const [initializing, setInitializing] = useState(true)
  const [page, setPage] = useState(null)

  // Project-specific state variables
  // all indicators
  const [indicators, setIndicators] = useState(null)
  const [recentIndicators, setRecentIndicators] = useState(null)

  // all areas
  const [areas, setAreas] = useState(null)
  const [recentAreaScores, setRecentAreaScores] = useState(null)

  // all area adoption counts
  const [areaAdoptions, setAreaAdoptions] = useState(null)
  const [recentAreaAdoptions, setRecentAreaAdoptions] = useState(null)

  // metric ID for "overall score"
  const [overallId, setOverallId] = useState(null)
  const [overallAdoptionId, setOverallAdoptionId] = useState(null)
  const [
    overallRecentScoreThresholdId,
    setOverallRecentScoreThresholdId,
  ] = useState(null)
  const [overallRecentAdoptionId, setOverallRecentAdoptionId] = useState(null)

  // EFFECT HOOKS // -------—-------—-------—-------—-------—-------—-------—//
  useEffect(() => {
    // get metric IDs, names, etc. for use throughout the site
    getParsedMetrics().then(
      ({
        newIndicators,
        newRecentIndicators,
        newAreas,
        newAreaAdoptions,
        newRecentAreaScores,
        newRecentAreaAdoptions,
        overallId,
        overallRecentScoreThresholdId,
        overallRecentAdoptionId,
        overallAdoptionId,
      }) => {
        setIndicators(newIndicators)
        setRecentIndicators(newRecentIndicators)
        setAreas(newAreas)
        setRecentAreaScores(newRecentAreaScores)
        setAreaAdoptions(newAreaAdoptions)
        setRecentAreaAdoptions(newRecentAreaAdoptions)
        setOverallId(overallId)
        setOverallAdoptionId(overallAdoptionId)
        setOverallRecentScoreThresholdId(overallRecentScoreThresholdId)
        setOverallRecentAdoptionId(overallRecentAdoptionId)
        setLoading(false)
        setInitializing(false)
      }
    )
  }, [])

  return (
    <>
      <Layout page={page}>
        <SEO
          title="Map"
          description="HIV Policy Lab map of global HIV policies"
        />
        {!initializing && (
          <Map
            {...{
              path: '/map/',
              page,
              setPage,
              setLoading,
              indicators,
              recentIndicators,
              areas,
              areaAdoptions,
              recentAreaScores,
              recentAreaAdoptions,
              overallId,
              overallAdoptionId,
              overallRecentScoreThresholdId,
              overallRecentAdoptionId,
            }}
          />
        )}
      </Layout>
      <LoadingSpinner {...{ loading }} />
    </>
  )
}

export default IndexPage

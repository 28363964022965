/**
 * Page for a Mapbox map and related controls.
 * All data elements are defined in `plugins` directory on a per-project basis.
 * `plugins/data.js` defines metric metadata, metric data getter methods, etc.,
 *    , and default settings
 * `plugins/sources.js` defines the map sources and styles.
 * `plugins/layers.js` defines the layers and styles
 */

// standard packages
import React, { useEffect, useState } from 'react'

// 3rd party packages
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames'

// local packages
import { defaults } from '../../common/MapboxMap/plugins/data'
import { mapStyles } from '../../common/MapboxMap/plugins/sources'
import MapSearch from '../../common/MapboxMap/MapSearch/MapSearch'

// assets and styles
import styles, { style } from './map.module.scss'
import imgSrc from '../../../assets/icons/info.svg'

// common components
import { MapboxMap, RadioToggle, OptionsMenu } from '../../common'

// FUNCTION COMPONENT // ----------------------------------------------------//
const MapboxPage = ({
  setPage,
  setLoading,
  indicators,
  recentIndicators,
  areas,
  recentAreaScores,
  areaAdoptions,
  recentAreaAdoptions,
  overallId,
  overallRecentScoreThresholdId,
  overallAdoptionId,
  overallRecentAdoptionId,
}) => {
  // CONSTANTS // -----------------------------------------------------------//
  const yearOptionsSpecific = [
    2024,
    2023,
    2022,
    2021,
    2020,
    2019,
    2018,
    2017,
  ].map(d => {
    return {
      value: `${d}-01-01`,
      name: d.toString(),
    }
  })
  const yearOptions = [{ value: 'recent', name: 'Most recent data' }].concat(
    yearOptionsSpecific
  )

  // STATE // ---------------------------------------------------------------//
  // unique ID of map to display, e.g., 'us', 'global'
  const [mapId] = useState(defaults.mapId)

  // default date of the map viewer -- `defaults.date` must be YYYY-MM-DD str
  const [date, setDate] = useState(
    defaults.date === 'recent' ? defaults.date : new moment(defaults.date)
  )

  // name of metric to use as fill by default
  const [fill, setFill] = useState(defaults[mapId].fill)

  // name of metric to use as circle by default
  const [circle, setCircle] = useState(defaults[mapId].circle)

  // input search value for map search bar
  const [searchValue] = useState(undefined)

  // chosen search result for map search bar
  const [searchChoice, setSearchChoice] = useState(undefined)

  // geom search results
  const [searchResults] = useState([])

  // currently selected filters
  const [filters] = useState({})

  // Project-specific state variables // ----------------------------------- //
  // currently selected indicator
  const [indicator, setIndicator] = useState(indicators[8].value)

  // currently selected area
  const [area, setArea] = useState(areas[1].value)

  // whether the mobile version of the menu is open by default
  const [open, setOpen] = useState(false)
  const [openDesktop, setOpenDesktop] = useState(true)

  // EFFECT HOOKS // --------------------------------------------------------//
  // on first render, set page name
  useEffect(function () {
    setPage('map')
  }, [])

  // when map style changes, update default metrics selected
  useEffect(
    function updateDefaultMetrics() {
      setCircle(defaults[mapId].circle)
      setFill(defaults[mapId].fill)
    },
    [mapId]
  )

  // JSX // -----------------------------------------------------------------//
  // constants for JSX
  const indicatorName = indicators.find(d => +d.value === +indicator).name
  const getMetricNameAndId = () => {
    let datumLevel
    switch (fill) {
      case 'indicator':
        const indCountToUse = date === 'recent' ? recentIndicators : indicators
        const datum = indCountToUse.find(d => d.name === indicatorName)
        return [datum.name, datum.value]
      case 'area':
        // get area overall status level
        const datumLevelAreasToUse =
          date === 'recent' ? recentAreaScores : areas
        const datumLevelReference = areas.find(d => +d.value === +area)
        datumLevel = datumLevelAreasToUse.find(
          d => d.name === datumLevelReference.name
        )

        // get area adoption status counts
        const areaCountToUse =
          date === 'recent' ? recentAreaAdoptions : areaAdoptions
        const datumCounts = areaCountToUse.find(d => d.name === datumLevel.name)
        return [datumLevel.name, datumLevel.value + ',' + datumCounts.value]
        break
      case 'overall':
        const countIds = overallAdoptionId

        const countIdsToUse =
          date === 'recent' ? overallRecentAdoptionId : countIds

        const idToUse =
          date === 'recent' ? overallRecentScoreThresholdId : overallId
        return ['Overall adoption score', idToUse + ',' + countIdsToUse]
      default:
        return ['', '']
    }
  }
  const [metricName, metricId] = getMetricNameAndId()

  // define option menu to show

  let title
  if (fill === 'indicator') {
    title = 'Policy adoption'
  } else if (fill === 'area') {
    title = 'Policy category adoption'
  } else {
    title = 'Overall policy adoption'
  }
  const subtitle =
    fill !== 'overall' ? (
      <div className={styles.subtitle}>{metricName}</div>
    ) : null
  // const subtitle =
  //   date !== 'recent' ? (
  //     <div className={styles.subtitle}>{date.format('YYYY')}</div>
  //   ) : null
  // define dynamic title
  let tipContent = null
  if (fill === 'area') {
    switch (metricName) {
      case 'Clinical and treatment':
        tipContent = 'Policies on HIV treatment and related health services'
        break
      case 'Testing and prevention':
        tipContent =
          'Policies on biomedical and soci-behavioral HIV prevention and HIV testing'
        break
      case 'Structural':
        tipContent =
          'Policies on political and social drivers of HIV, including criminalization, gender, and human rights issues'
        break
      case 'Health systems':
        tipContent = 'Policies on financing and management of the health system'
        break
      default:
        tipContent = 'Policies on HIV treatment and related health services'
    }
  }
  const overlays = [
    <div key={'map-title-content'} className={styles.mapTitle}>
      <div className={styles.title}>
        {title}
        <div className={styles.sub}>
          {subtitle}
          <span
            className={classNames(styles.tipContainer, {
              [styles.hide]: fill !== 'area',
            })}
          >
            <img
              className={styles.infoIcon}
              src={imgSrc}
              data-tip={tipContent}
              data-for="simpleTip"
            />
          </span>
        </div>
      </div>
    </div>,
    <OptionsMenu
      key={'OptionsMenu'}
      {...{
        open,
        setOpen,
        openDesktop,
        setOpenDesktop,
        content: [
          // fill metric radio toggle - desktop
          <RadioToggle
            key={'metric-radio-desktop'}
            {...{
              choices: [
                { label: 'Overall', value: 'overall' },
                { label: 'Policy category', value: 'area' },
                { label: 'Specific policy', value: 'indicator' },
              ],
              curVal: fill,
              callback: setFill,
              selectpicker: false,
              label: 'View policy adoption by',
              key: 'mode-desktop',
              className: styles.desktopOnly,
            }}
          />,

          // fill metric radio toggle - mobile
          <RadioToggle
            key={'metric-radio-mobile'}
            {...{
              choices: [
                { label: 'Overall', value: 'overall' },
                { label: 'Policy category', value: 'area' },
                { label: 'Specific policy', value: 'indicator' },
              ],
              curVal: fill,
              callback: setFill,
              selectpicker: true,
              label: 'View policy adoption by',
              key: 'mode-mobile',
              className: styles.mobileOnly,
            }}
          />,

          // policy indicator
          fill === 'indicator' && (
            <RadioToggle
              key={'indicator-radio'}
              {...{
                choices: indicators,
                curVal: indicator,
                callback: setIndicator,
                selectpicker: true,
                label: 'Select specific policy',
                key: 'indicator',
                className: styles.mobile,
              }}
            />
          ),
          // policy area
          fill === 'area' && (
            <RadioToggle
              key={'area-radio'}
              {...{
                choices: areas,
                curVal: area,
                callback: setArea,
                selectpicker: true,
                label: 'Select policy category',
                key: 'area',
                className: styles.mobile,
              }}
            />
          ),

          // year
          <RadioToggle
            key={'year-radio'}
            {...{
              tooltip: (
                <div>
                  'Most recent data' shows most recent policy adoption levels.
                  Choosing a year shows data available for that year only.
                </div>
              ),
              choices: yearOptions,
              curVal: date === 'recent' ? date : date.format('YYYY-MM-DD'),
              callback: v => {
                if (v !== 'recent') setDate(new moment(v))
                else setDate(v)
              },
              selectpicker: true,
              label: 'Show data as of',
              key: 'year',
              className: styles.mobile,
            }}
          />,
          <MapSearch
            key={'mapSearch'}
            onSelect={v => {
              setSearchChoice(v)
              setOpen(false)
            }}
            results={searchResults}
          />,
        ],
      }}
    />,
  ]

  // collate mapbox component for the currently enabled maps
  // for each map defined in `mapStyles` (see ./plugins/sources.js):
  const maps = []
  for (const [k] of Object.entries(mapStyles)) {
    // add the mapbox map component for the map to the array as long as it is
    // the currently enabled map
    if (k === mapId)
      maps.push(
        <MapboxMap
          {...{
            overlays,
            mapId: k,
            key: k,
            mapStyle: mapStyles[k],
            date,
            circle,
            filters,
            searchValue,
            searchChoice,
            setSearchChoice,
            setLoading,
            // plugins
            fill,
            code: fill === 'indicator' ? metricName.split(' - ')[0] : null,
            indicator: metricName,
            metricName: metricName,
            metric_id: metricId,
          }}
        />
      )
  }
  return (
    <div className={style}>
      {
        // Drawer: holds map options
      }

      {
        // display map component(s)
      }
      {maps}
      <ReactTooltip type="light" id="simpleTip" place="right" effect="solid" />
    </div>
  )
}

export default MapboxPage
